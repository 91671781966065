import React from 'react'
import Header from './Header'
import '../App.css';
import Heart from '../assets/heart.png'
import HeroImg from '../assets/hero_image.png'
import HeroImgback from '../assets/hero_image_back.png'
import Calories from '../assets/calories.png'
import { motion } from 'framer-motion';
import NumberCounter from 'number-counter'

function Hero() {
  const transition = {type: 'spring', duration: 3}

  const mobile = window.innerWidth <= 768 ? true: false;

  return (
    <div className='flex flex-col items-center lg:items-start lg:flex-row justify-between lg:pl-12'>

      <div className="blur w-32 lg:w-[22rem] h-[30rem] left-0"></div>

        {/* Left part of the Hero section  */}
        <div id='home' className='w-[80%] lg:w-3/4 flex gap-4 flex-col items-center lg:items-start'>
            <Header/>

            {/* Hero Heading */}
            <div className='mt-3 lg:mt-16 lg:mb-8 text-[10px] lg:text-base bg-gray-700 rounded-[4rem] px-3 py-2 lg:px-5 lg:py-3 text-white uppercase w-fit relative flex items-center justify-start'>
                <motion.div initial={{left: mobile? "145px" : "238px"}} whileInView={{left: '8px'}} transition={{...transition, type: 'tween'}} className='absolute bg-[#f48915] w-16 lg:w-[5.4rem] h-[80%] left-2 lg:bottom-1 rounded-[3rem] z-10'></motion.div>
                <span className='z-30 relative'>the best fitness club in the town</span>
            </div>

            {/* Hero heading */}
            <div className='heroText my-5 lg:my-0 flex flex-col text-4xl gap-3 items-center justify-center lg:justify-start lg:items-start lg:gap-6 uppercase lg:text-7xl font-bold text-white'>
              <div>
                <span className='strock-text'>Shape </span>
                <span>Your</span>
              </div>
              <div>
                <span>Ideal body</span>
              </div>
              <div>
                <span className='text-center lg:text-start text-sm ml-2 lg:text-base font-light normal-case lg:w-1/2 inline-block my-3'>In here we help you to shape and build your ideal body and live up your life to fullest</span>
              </div>
            </div>

            {/* Figures section */}
            <div className='figures flex gap-10'>
              <div className='flex flex-col'>
                <span className='text-white font-bold text-2xl lg:text-3xl flex gap-x-1 lg:gap-x-2'>+<NumberCounter end={140} start={100} delay={'4'}/></span>
                <span className='text-gray-400 text-sm normal-case'>Expert coachs</span>
              </div>

              <div className='flex flex-col'>
                <span className='text-white font-bold text-2xl lg:text-3xl flex gap-x-1 lg:gap-x-2'>+<NumberCounter end={978} start={900} delay={'4'}/></span>
                <span className='text-gray-400  text-sm normal-case'>Members joined</span>
              </div>

              <div className='flex flex-col'>
                <span className='text-white font-bold text-2xl lg:text-3xl flex gap-x-1 lg:gap-x-2'>+<NumberCounter end={50} start={10} delay={'4'}/></span>
                <span className='text-gray-400 no text-sm normal-case'>Fitness programs</span>
              </div>
            </div>

            {/* Hero Buttons */}
            <div className='flex gap-x-10 lg:gap-4 my-4 font-normal'>
              <button className='bg-[#f48915] text-white text-base p-2 font-bold border-2 border-transparent transition-all duration-200 ease-in cursor-pointer lg:hover:bg-transparent lg:hover:border-2 lg:hover:border-[#f48915] lg:hover:text-[#f48915]'>Get Started</button>
              <button className=' bg-transparent text-[#f48915] text-base p-2 font-bold border-2 border-[#f48915] transition-all duration-200 ease-in cursor-pointer lg:hover:bg-[#f48915] lg:hover:text-white'>Learn More</button>
            </div>


        </div>


        {/* Right part of the Hero section  */}
        <div className='lg:w-1/4 relative lg:h-[91vh] lg:bg-[#f48915]'>
          
        <button className='absolute right-1 top-20 z-10 lg:right-12 lg:top-4 bg-white text-black px-3 py-2 font-medium border-2 border-transparent transition-all duration-200 ease-in cursor-pointer hover:bg-gray-300 hover:border-2 '>Join Now</button>
          
          {/* Heart */}
          <motion.div transition={transition} whileInView={{right: '4rem'}} initial={{right: '-1rem'}} className='hidden lg:flex flex-col gap-4 bg-gray-600 w-fit p-4 items-start rounded-lg absolute left-[11.3rem] top-28'>
            <img className='w-2/5' src={Heart} alt="Gym Bros" />
            <span className='text-gray-300 text-sm lg:text-base'>Heart Rate</span><span className='text-white text-lg lg;text-2xl'>116 bpm</span>
          </motion.div>

          {/* Heart - Design For Mobile Devices  */}
          <motion.div transition={transition}  className='lg:hidden flex flex-col gap-4 bg-gray-600 w-fit p-4 items-start rounded-lg relative top-20 -left-8 z-10'>
            <img className='w-2/5' src={Heart} alt="Gym Bros" />
            <span className='text-gray-300 text-sm lg:text-base'>Heart Rate</span><span className='text-white text-lg lg;text-2xl'>116 bpm</span>
          </motion.div>

          {/* Hero Images */}
          <img src={HeroImg} alt="" className='relative lg:absolute top-16 lg:top-[9.8rem] left-14 lg:-left-32 w-60 self-center lg:w-[23rem] z-20'/>
          <motion.img initial={{right: '11rem'}} whileInView={{right: '20rem'}} transition={transition} src={HeroImgback} alt="" className='hidden absolute top-16 right-80 z-10 w-[15rem]'/>
          <motion.img  transition={transition} src={HeroImgback} alt="" className='lg:hidden absolute top-20 left-8  w-[15rem]'/>

          {/* Calories */}
          <motion.div transition={transition} whileInView={{left: '-22rem'}} initial={{left: '-50rem'}} className='hidden lg:flex gap-x-10 absolute top-[35rem] left-[-22rem] rounded-lg bg-gray-500 py-3 px-5'>
            <img className='w-[4vw]' src={Calories} alt="" />
            <div className='flex flex-col gap-y-4  pr-10'>
              <span className='text-slate-400'>Calories Burned</span>
              <span className='text-white'>220 kacl</span>
            </div>
          </motion.div>

           {/* Calories - Design For Mobile Devices  */}
          <motion.div className='lg:hidden flex gap-x-8 relative top-20 rounded-lg bg-gray-500 py-3 px-3'>
            <img className='w-10' src={Calories} alt="" />
            <div className='flex flex-col gap-y-2'>
              <span className='text-slate-400'>Calories Burned</span>
              <span className='text-white'>220 kacl</span>
            </div>
          </motion.div>
        </div>
    </div>
  )
}

export default Hero

