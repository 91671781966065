import './App.css';
import Footer from './components/Footer';
import CopyFooter from './components/CopyFooter';
import Hero from './components/Hero';
import Join from './components/Join';
import Plans from './components/Plans';
import Programs from './components/Programs';
import Reasons from './components/Reasons';
import Testimonials from './components/Testimonials';
function App() {
  return (
    <div className='bg-[#3c3f45]'>
      <Hero/>
      <div className='lg:py-10 lg:mt-0 mt-20 py-10'>
      <Programs/>
      </div>
      <div>
        <Reasons/>
      </div>
      <div className='lg:my-3'>
        <Plans/>
      </div>
      <div>
        <Testimonials/>
      </div>
      <div className='py-5'>
        <Join/>
      </div>
      <div className='mt-8'>
        <Footer/>
      </div>
      <CopyFooter/>
    </div>
  );
}

export default App;
