import React from 'react';
import Code from "../assets/Codelogo.png";
import { FaLinkedinIn} from "react-icons/fa";
import { BsInstagram,BsGlobe, BsGithub } from "react-icons/bs";
import { GrMail } from "react-icons/gr";

function Footer() {
  return (
    <>
        <footer className="p-4 bg-[#06063e] shadow md:px-6 md:py-8">
            <div className="sm:flex sm:items-center md:justify-between lg:justify-between">
                <a href="/" className="justify-center flex items-center mb-4 sm:mb-0">
                    <img src={Code} className="mr-3 h-10" alt="Ritsux.coder"/>
                    <span className="self-center text-2xl text-white font-semibold whitespace-nowrap font-Itim dark:text-white">Code With Rits</span>
                </a>
            
                <div className='flex justify-center items-center gap-x-10 text-gray-100 my-6'>
                    <a href="https://www.linkedin.com/in/sk-ripon-a82a7b222/" target={"_blank"} rel="noreferrer" className='text-xl hover:text-pink-500'><FaLinkedinIn/></a>
                    <a href="https://www.instagram.com/ritsux.coder/" target={"_blank"} rel="noreferrer" className='text-xl hover:text-pink-500'><BsInstagram/></a>
                    <a href="https://github.com/myselfripon20/" target={"_blank"} rel="noreferrer" className='text-xl hover:text-pink-500'><BsGithub/></a>
                    <a href="https://skriponux.pages.dev/" target={"_blank"} rel="noreferrer" className='text-xl hover:text-pink-500'><BsGlobe/></a>
                    <a href="mailto:myselfripon20@gmail.com" target={"_blank"} rel="noreferrer" className='text-xl hover:text-pink-500'><GrMail/></a>
                </div>

                
            </div>
            <hr className="my-4 border-gray-400 sm:mx-auto dark:border-gray-700 lg:my-8"/>
            <span className="text-center block text-sm text-gray-200 sm:text-center dark:text-gray-400">All rights reserved to &copy;<a href="https://codewithrits.pages.dev/" className="hover:text-amber-400 underline">
             Code With Rits</a> - 2022-23. Made With ❤️ In India. 
            </span>
        </footer>
        
    </>

  )
}

export default Footer;