import React from 'react';
import Github from '../assets/github.png';
import Instagram from '../assets/instagram.png';
import Linkedin from '../assets/linkedin.png';
import Logo from '../assets/gymbro.png';

function Footer() {
  return (
    <div className="footer relative">
        <hr className='border border-gray-500'/>
        <div className='pt-12 px-8 flex flex-col gap-16 items-center justify-center'>
            <div className="social flex gap-16">
                <img className='w-8 h-8 cursor-pointer' src={Github} alt="gymbros" />
                <img className='w-8 h-8 cursor-pointer' src={Instagram} alt="gymbros" />
                <img className='w-8 h-8 cursor-pointer' src={Linkedin} alt="gymbros" />
            </div>

            <div className="logo">
                <p className='text-gray-100 text-center'>&copy;GymBros 2023.</p>
                <img src={Logo} alt="gymbros" />
            </div>
        </div>

        <div className="hidden lg:block blur-red w-[10rem] h-[5rem] lg:w-[26rem] lg:h-[12rem] bottom-0 lg:right-[15%]"></div>
        <div className="hidden lg:block blur w-[10rem] h-[5rem] lg:w-[26rem] lg:h-[12rem] bottom-0 lg:left-[15%]"></div>
    </div>
  )
}

export default Footer