import React from 'react';
import {plansData} from '../data/plansData';
import whiteTick from '../assets/whiteTick.png'

function Plans() {
  return (
    <div id='plans' className='plans-con -mt-4 px-8 flex flex-col gap-16 relative '>

        {/* Blur Effect  */}
        <div className=" hidden lg:block blur lg:w-[32rem] lg:h-[23rem] w-[16rem] h-[10rem] top-24 left-0 "></div>
        <div className="hidden lg:block blur lg:w-[32rem] lg:h-[23rem] w-[16rem] h-[10rem] top-40 right-0 "></div>

        <div className='header flex justify-center mt-10 lg:mt-0 lg:gap-16 font-bold text-xl lg:text-5xl uppercase italic text-white'>
            <span className='strock-text'>READY TO START</span>
            <span>YOUR JOURNEY</span>
            <span className='strock-text'>NOW WITH US</span>
        </div>

        {/* Plans Card  */}
        <div className='plans flex flex-col lg:flex-row items-center justify-center gap-12 lg:py-4 z-10'>
            {plansData.map((plan, i)=>(
                <div className='plan flex flex-col justify-start bg-[#656565] text-white gap-y-4 lg:gap-y-6 py-8 px-6 w-[18rem]' key={i}>
                    {plan.icon}
                    <span className='text-base font-bold'>{plan.name}</span>
                    <span className='text-5xl font-bold'>$ {plan.price}</span>

                    <div className="features flex flex-col gap-4">
                        {plan.features.map((feature, i)=>(
                            <div className="feature flex items-center gap-4">
                                <img className='w-4' src={whiteTick} alt="" />
                                <span key={i}>{feature}</span>
                            </div>
                        ))}
                    </div>

                    <div>
                        <span className='text-sm text-gray-200 cursor-pointer'>See more benefits -&gt;</span>
                    </div>
                    <button className='bg-white text-black p-2 font-semibold'>Join Now</button>
                </div>
            ))}
        </div>
    </div>
  )
}

export default Plans