import React from "react";
import { useRef } from "react";
import emailjs from '@emailjs/browser'

function Join() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_v3efmat', 'template_6casxq8', form.current, 'x79CLFVglSwfvssHW')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div className="Join flex lg:flex-row flex-col mx-6 lg:gap-40" id="join-us">
      <div className="left-j text-white text-2xl lg:text-5xl uppercase font-bold ">
        <hr className="w-44 border-2 border-[#f48915] rounded-xl mb-2"/>
        <div>
          <span className="strock-text mr-3">READY TO</span>
          <span>LEVEL UP</span>
        </div>

        <div>
          <span>YOUR BODY</span>
          <span className="strock-text ml-3">WITH US?</span>
        </div>
      </div>

      <div className="right-j flex justify-center items-end">

        <form onSubmit={sendEmail} ref={form} className="email-form flex lg:gap-12 bg-gray-600 p-3 lg:py-4 lg:px-8 mt-6 lg:mt-0 ">
          <input className="bg-transparent border-none outline-none text-gray-100" type="email" name="user_email" id="" placeholder="Enter your Email Address"/>
          <button id="" className='bg-gray-100 text-gray-600 p-2 font-bold active:bg-gray-300 cursor-pointer text-sm px-4 lg:text-base'>Join Now</button>
        </form>
      </div>
    </div>
  ); 
}

export default Join;
