import React, { useState } from 'react';
import Logo from '../assets/gymbro.png'
import Bars from '../assets/bars.png'
import { Link } from 'react-scroll';


function Header() {
  const mobile = window.innerWidth <= 768 ? true: false;
  const [openedMenu, setOpenedMenu] = useState(false)
 
  return (
    <>
    <div className='hidden lg:flex items-center justify-between px-6'>
        <img className='w-[20%]' src={Logo} alt="Gymbro" />

        <ul className='flex gap-x-10 items-center text-white'>
            <Link to='home' activeClass='active' span={true} smooth={true} className='cursor-pointer hover:text-[#f48915] transition-all ease-in 1s'>Home</Link>
            <Link to='programs' span={true} smooth={true} className='cursor-pointer hover:text-[#f48915] transition-all ease-in 1s'>Programs</Link>
            <Link to='reasons' span={true} smooth={true} className='cursor-pointer hover:text-[#f48915] transition-all ease-in 1s'>Why us</Link>
            <Link to='plans' span={true} smooth={true} className='cursor-pointer hover:text-[#f48915] transition-all ease-in 1s'>Plans</Link>
            <Link to='testimonials' spy={true} smooth={true} className='cursor-pointer hover:text-[#f48915] transition-all ease-in 1s'>Testimonials</Link>
        </ul>
    </div>

    <div className='lg:hidden flex flex-col'>
      <img className='flex items-center justify-center' src={Logo} alt="Gymbro" />
      {openedMenu === false && mobile === true ? (
        <div onClick={()=>setOpenedMenu(true)} className='flex justify-center items-center cursor-pointer'>
          <img className='w-10' src={Bars} alt="" />
        </div>
      ):(
      <ul className='absolute z-40 px-20 left-12 top-44 flex flex-col gap-y-6 py-6 rounded-md bg-orange-400 items-center text-white'>
            <Link to='home' activeClass='active' span={true} smooth={true} onClick={()=> setOpenedMenu(false)} className='active:text-gray-300'>Home</Link>
            <Link to='programs' span={true} smooth={true} onClick={()=> setOpenedMenu(false)} className='active:text-gray-300'>Programs</Link>
            <Link to='reasons' span={true} smooth={true} onClick={()=> setOpenedMenu(false)} className='active:text-gray-300'>Why us</Link>
            <Link to='plans' span={true} smooth={true} onClick={()=> setOpenedMenu(false)} className='active:text-gray-300'>Plans</Link>
            <Link to='testimonials' span={true} smooth={true} onClick={()=> setOpenedMenu(false)} className='active:text-gray-300'>Testimonials</Link>
      </ul>
      )}
    </div>
    </>
  )
}

export default Header