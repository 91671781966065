import React from 'react'
import Image1 from '../assets/image1.png'
import Image2 from '../assets/image2.png'
import Image3 from '../assets/image3.png'
import Image4 from '../assets/image4.png'
import Nike from '../assets/nike.png'
import Adidas from '../assets/adidas.png'
import nb from '../assets/nb.png'
import tick from '../assets/tick.png'
import '../App.css';

function Reasons() {
  return (
    <div id='reasons' className='reasons px-8 flex lg:flex-row flex-col'>
        <div className='left'>
            <img src={Image1} alt="GymBros" />
            <img src={Image2} alt="GymBros" />
            <img src={Image3} alt="GymBros" />
            <img src={Image4} alt="GymBros" className='lg:w-[155px] lg:h-[181px]'/>
        </div>
        <div className='right uppercase gap-4 flex flex-col my-5 lg:my-0'>
            <span className='font-bold text-[#f48915]'>Some reasons</span>
            <div className='text-white text-3xl lg:text-5xl font-bold'>
              <span className='strock-text mr-1'>why</span>
              <span>choose us?</span>
            </div>

            <div className='details flex flex-col gap-y-4 text-sm lg:text-base lg:gap-y-8 uppercase text-white'>
              <div className='flex gap-4 items-center'>
                <img src={tick} alt="GymBros" className='w-8'/>
                <span>over 140+ expert coachs </span>
              </div>

              <div className='flex gap-4 items-center'>
                <img src={tick} alt="GymBros" className='w-8'/>
                <span>Train smarter and faster than before</span>
              </div>

              <div className='flex gap-4 items-center'>
                <img src={tick} alt="GymBros" className='w-8'/>
                <span>1 free program for new member</span>
              </div>

              <div className='flex gap-4 items-center'>
                <img src={tick} alt="GymBros" className='w-8'/>
                <span>reliable partners</span>
              </div>

            </div>

            {/* Our Partnars */}
            <span className='uppercase text-gray-400 mt-8'>Our Partnars</span>
            <div className='flex gap-x-5'>
              <img className='w-10' src={nb} alt="Gymbros" />
              <img className='w-10' src={Adidas} alt="Gymbros" />
              <img className='w-10' src={Nike} alt="Gymbros" />
            </div>
        </div>
    </div>
  )
}

export default Reasons