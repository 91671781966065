import React from "react";
import "../App.css";
import { programsData } from "../data/programsData";
import RightArrow from '../assets/rightArrow.png'

function Programs() {
  return (
    <div id="programs" className="flex flex-col gap-8 px-8">
      {/* Header */}
      <div className="flex flex-col lg:flex-row items-center justify-center gap-6 lg:gap-20 font-bold text-3xl lg:text-5xl uppercase italic text-white">
        <span className="strock-text">Explore our</span>
        <span>programs</span>
        <span className="strock-text">to shape you</span>
      </div>

      {/* Program cards */}
      <div className="flex flex-col lg:flex-row flex-wrap lg:flex-nowrap justify-center items-center gap-4">
        {programsData.map((program) => {
            return <div className="items flex flex-col bg-gray-500 text-white gap-4 p-8 justify-between hover:bg-">
                {program.image}
                <span>{program.heading}</span>
                <span>{program.details}</span>
                <div className="flex items-center gap-x-3">
                    <span>Join Now</span>
                    <span><img className="w-3/5" src={RightArrow} alt="" /></span>
                </div>
            </div>
        })}
      </div>
    </div>
  );
}

export default Programs;
