import React, {useState} from 'react';
import {testimonialsData} from '../data/testimonialsData'
import leftArrow from '../assets/leftArrow.png'
import rightArrow from '../assets/rightArrow.png'
import { motion } from 'framer-motion';


function Testimonials() {
    const [selected, setSelected] = useState(0);
    const tLenght = testimonialsData.length;
    const transition = {type: 'spring', duration: 3}
  return (
    <div className='testimonials flex flex-col lg:flex-row gap-2 lg:gap-4 p-8'>
        <div className="left-t flex flex-1 gap-8 flex-col uppercase text-white">
            <span className='text-[#f48915] font-bold'>Testimonials</span>
            <span className='strock-text font-bold text-3xl lg:text-5xl'>what they</span>
            <span className=' font-bold text-3xl lg:text-5xl'>say about us</span>

            {/* Testimonials Data - Review  */}
            <motion.span
            key={selected}
            initial={{opacity:0, x: -100}} 
            animate={{opacity:1, x: 0}} 
            exit={{opacity:0, x: 100}} 
            transition={transition}
            className='normal-case lg:text-base text-sm font-light lg:font-normal tracking-wide leading-6 lg:leading-8'>
                {testimonialsData[selected].review}
            </motion.span>

            <span>
                <span className='text-[#f48915]'>
                    {testimonialsData[selected].name}
                </span> {" "}
                - {testimonialsData[selected].status}
            </span>
        </div>

        <div className="right-t flex-1 relative">
            <motion.div initial={{opacity:0, x: -100}} transition={{...transition, duration: 2}} whileInView={{opacity: 1, x: 100}} className="hidden lg:block testimonial-img-div absolute w-[17rem] h-[20rem] top-12 right-52"></motion.div>
            <motion.div initial={{opacity:0, x: 100}} transition={{...transition, duration: 2}} whileInView={{opacity: 1, x: 0}} className="absolute w-[17rem] h-[20rem] border-2 border-[#f48915] bg-transparent lg:right-[9rem] lg:top-[0.9rem]"></motion.div>
            
            <motion.img
            key={selected}
            initial={{opacity:0, x: 100}} 
            animate={{opacity:1, x: 0}} 
            exit={{opacity:0, x: -100}} 
            transition={transition}
            className='lg:absolute p-2 lg:p-0 w-[17rem] h-[20rem] object-cover lg:right-32 lg:top-8' src={testimonialsData[selected].image} alt="" />

            <div className='flex mt-6 lg:mt-0 justify-center gap-x-8 lg:absolute lg:bottom-4 lg:left-12'>
                <img onClick={()=>{selected===0 ? setSelected(tLenght - 1) : setSelected((prev) => prev - 1)}} className='w-6 h-6 cursor-pointer active:bg-orange-300 rounded-full ' src={leftArrow} alt="" />
                <img onClick={()=>{selected===tLenght - 1 ? setSelected(0) : setSelected((prev) => prev + 1)}} className='w-6 h-6 cursor-pointer active:bg-orange-300 rounded-full ' src={rightArrow} alt="" />
            </div>
        </div>
    </div>
  )
}

export default Testimonials